<template>
    <div>
        <md-table v-if="items" md-sort="name" md-sort-order="asc" md-card>
            <md-table-toolbar>
                <h1 class="md-title">Family statistics</h1>
            </md-table-toolbar>
            <md-table-row>
                <md-table-head>Family ID</md-table-head>
                <md-table-head class="md-sortable" :class="{'md-sorted': this.sortCol.tasks_count}"
                               @click.native="customSort('tasks_count')">
                    <md-icon v-if="this.sortCol.tasks_count === 'asc'">arrow_upward</md-icon>
                    <md-icon v-if="this.sortCol.tasks_count === 'desc'">arrow_downward</md-icon>
                    Active tasks</md-table-head>
                <md-table-head class="md-sortable" :class="{'md-sorted': this.sortCol.completed_task_count}"
                               @click.native="customSort('completed_task_count')">
                    <md-icon v-if="this.sortCol.completed_task_count === 'asc'">arrow_upward</md-icon>
                    <md-icon v-if="this.sortCol.completed_task_count === 'desc'">arrow_downward</md-icon>
                    Completed tasks</md-table-head>
                <md-table-head class="md-sortable" :class="{'md-sorted': this.sortCol.rewards_count}"
                               @click.native="customSort('rewards_count')">
                    <md-icon v-if="this.sortCol.rewards_count === 'asc'">arrow_upward</md-icon>
                    <md-icon v-if="this.sortCol.rewards_count === 'desc'">arrow_downward</md-icon>
                    Active rewards</md-table-head>
                <md-table-head class="md-sortable" :class="{'md-sorted': this.sortCol.completed_rewards_count}"
                               @click.native="customSort('completed_rewards_count')">
                    <md-icon v-if="this.sortCol.completed_rewards_count === 'asc'">arrow_upward</md-icon>
                    <md-icon v-if="this.sortCol.completed_rewards_count === 'desc'">arrow_downward</md-icon>
                    Completed rewards</md-table-head>
                <md-table-head class="md-sortable" :class="{'md-sorted': this.sortCol.created_at}"
                               @click.native="customSort('created_at')">
                    <md-icon v-if="this.sortCol.created_at === 'asc'">arrow_upward</md-icon>
                    <md-icon v-if="this.sortCol.created_at === 'desc'">arrow_downward</md-icon>
                    Registration date</md-table-head>
            </md-table-row>

            <md-table-row v-for="item in items" :key="item.id">
                <md-table-cell><router-link :to="{name: 'family-item', params: {id: item.id, page: curPage}}">{{ item.id }}</router-link></md-table-cell>
                <md-table-cell>{{item.tasks_count}}</md-table-cell>
                <md-table-cell>{{item.completed_task_count}}</md-table-cell>
                <md-table-cell>{{item.rewards_count}}</md-table-cell>
                <md-table-cell>{{item.completed_rewards_count}}</md-table-cell>
                <md-table-cell>{{item.created_at}}</md-table-cell>
            </md-table-row>
        </md-table>
        <div class="buttons">
            <md-field>
                <md-select v-model="perPage" name="per-page" id="per-page-items" placeholder="Per page" md-dense>
                    <md-option value="15">15</md-option>
                    <md-option value="20">20</md-option>
                    <md-option value="50">50</md-option>
                    <md-option value="100">100</md-option>
                </md-select>
            </md-field>
            <paginate
                    prev-class="prev-page"
                    next-class="next-page"
                    ref="paginate"
                    :initial-page="curPage"
                    :page-count="total"
                    :click-handler="paginate"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'">
            </paginate>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FamilyStat",
        data() {
            return {
                items: [],
                curPage: 1,
                total: 1,
                perPage: 15,
                sortCol: {}
            }
        },
        created() {
            this.loadData(this.$route.params.page ?? 1)
        },
        computed: {
        },
        watch: {
            perPage: function () {
                this.loadData(1)
            }
        },
        methods: {
            customSort(active) {
                if (!this.sortCol) {
                    this.sortCol[active] = 'asc'
                } else {
                    if (this.sortCol[active] === 'asc') {
                        this.sortCol = {}
                        this.sortCol[active] = 'desc'
                    } else {
                        this.sortCol = {}
                        this.sortCol[active] = 'asc'
                    }
                }
                this.loadData(1)
            },
            paginate(page) {
                this.loadData(page)
            },
            loadData(page) {
                this.items = []
                this.$dispatch("family/list", {page: page, per_page: this.perPage, sort_params: this.sortCol}).then(res => {
                    this.$refs.paginate.selected = page
                    this.curPage = page;
                    this.items = res.items;
                    this.total = res.total;
                });
            }
        }
    }
</script>

<style lang="scss">
    .buttons {
        display: flex;
        justify-content: flex-end;

        .md-field {
            width: 150px;
            flex: none;
        }
        .pagination {
            display: flex;
            list-style-type: none;

            .disabled, .disabled a {
                cursor: not-allowed;
            }

            li {
                padding: 10px;
                border: 1px solid #e0e0e0;
            }
            .active{
                border-color: #366ecc;
                a {
                    color: #366ecc;
                }
            }
            .prev-page, .next-page {
                border: none;
            }
        }
    }
</style>
